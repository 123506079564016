import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "./icons/Logo";
import { useEffect } from "react";

function Header() {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const isActive = (url) => (url === pathname && "!text-medium-grey") || "";
  useEffect(() => {
    setOpen(false);
  }, [pathname]);
  return (
    <header className="z-[100] sticky top-0 ">
      <div className="bg-aged-white font-bold w-full drop-shadow-primary">
        <div className="container">
          <div className="flex items-center justify-between py-[15px] w-full">
            <div className="flex items-center justify-between">
              <Link
                to="/"
                className="w-[115px] flex-shrink-0"
                aria-label="home"
              >
                <Logo />
              </Link>
              <div className="pl-12 ml-1 hidden xl:flex">
                <Link
                  to="/candidate"
                  className={`pr-3 border-r-2 border-r-medium-grey ${isActive(
                    "/candidate"
                  )} ${isActive("/")}`}
                >
                  Candidates
                </Link>
                <Link to="/client" className={`pl-3 ${isActive("/client")}`}>
                  Clients
                </Link>
              </div>
            </div>
            <div>
              <nav className="hidden xl:block">
                <div className="flex items-center">
                  <Link to="/team" className={`pr-12 ${isActive("/team")}`}>
                    Team
                  </Link>
                  <Link to="/about" className={`pr-12 ${isActive("/about")}`}>
                    About Us
                  </Link>
                  <Link to="/faq" className={`pr-12 ${isActive("/faq")}`}>
                    FAQs
                  </Link>
                  <Link
                    to="/investors"
                    className={`pr-12 ${isActive("/investors")}`}
                  >
                    Investors
                  </Link>
                  <Link
                    to="/contact-us"
                    className={`pr-12 ${isActive("/contact-us")}`}
                  >
                    Contact us
                  </Link>
                  {/* <Link to="/login" className="button">
                    Log in
                  </Link> */}
                </div>
              </nav>
              <nav className="xl:hidden flex items-center">
                {/* <Link to="/login" className="button mr-4">
                  Log in
                </Link> */}
                <div className="button-group">
                  <div
                    className={`menu hamburger menu-right ${
                      open ? "menu-open" : "menu-closed"
                    }`}
                  >
                    <button
                      onClick={() => setOpen((prev) => !prev)}
                      aria-label="toggle menu"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="none"
                        viewBox="0 0 32 32"
                        className="menu-button icon"
                      >
                        <path
                          fill="#383838"
                          d="M27.79 24.58c.567.001 1.113.24 1.524.669.41.429.655 1.014.682 1.635a2.567 2.567 0 0 1-.536 1.699c-.371.47-.893.765-1.458.825l-.212.011H4.21c-.567 0-1.113-.24-1.524-.668a2.525 2.525 0 0 1-.682-1.635 2.566 2.566 0 0 1 .536-1.699c.371-.47.893-.765 1.458-.825l.213-.011h23.578Zm0-11.29c.586 0 1.148.255 1.563.709a2.54 2.54 0 0 1 .647 1.71 2.54 2.54 0 0 1-.647 1.711 2.119 2.119 0 0 1-1.564.709H4.212a2.118 2.118 0 0 1-1.564-.709A2.54 2.54 0 0 1 2 15.71c0-.642.233-1.257.647-1.711a2.118 2.118 0 0 1 1.564-.709h23.578Zm0-11.29c.586 0 1.148.255 1.563.709A2.54 2.54 0 0 1 30 4.419a2.54 2.54 0 0 1-.647 1.711 2.119 2.119 0 0 1-1.564.709H4.212a2.118 2.118 0 0 1-1.564-.709A2.54 2.54 0 0 1 2 4.42c0-.642.233-1.258.647-1.711A2.118 2.118 0 0 1 4.211 2h23.578Z"
                        ></path>
                      </svg>
                    </button>
                    <div
                      className="menu-list"
                      style={{ height: open ? 315 : 0 }}
                    >
                      <Link
                        className={`button-link block ${isActive(
                          "/candidate"
                        )}`}
                        to="/candidate"
                      >
                        Candidates
                      </Link>
                      <Link
                        className={`button-link block ${isActive("/client")}`}
                        to="/client"
                      >
                        Clients
                      </Link>
                      <hr style={{ borderColor: "#E1E1E1" }} />
                      <Link
                        className={`button-link block ${isActive("/team")}`}
                        to="/team"
                      >
                        Meet the team
                      </Link>
                      <Link
                        className={`button-link block ${isActive("/about")}`}
                        to="/about"
                      >
                        About us
                      </Link>
                      <Link
                        className={`button-link block ${isActive("/faq")}`}
                        to="/faq"
                      >
                        FAQs
                      </Link>
                      <Link
                        className={`button-link block ${isActive(
                          "/investors"
                        )}`}
                        to="/investors"
                      >
                        Investors
                      </Link>
                      <Link
                        className={`button-link block ${isActive(
                          "/contact-us"
                        )}`}
                        to="/contact-us"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
