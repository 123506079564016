import React from "react";
import * as Dialog from "@radix-ui/react-dialog";

const Modal = ({ title, description, open, onOpenChange, children }) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Portal>
      <Dialog.Overlay className="bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0 z-[101]" />
      <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] z-[101] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] focus:outline-none">
        {title && (
          <Dialog.Title className="m-0 text-22 font-semibold">
            {title}
          </Dialog.Title>
        )}

        {description && (
          <Dialog.Description className="mt-2 mb-5 text-16">
            {description}
          </Dialog.Description>
        )}

        {children}

        <Dialog.Close asChild>
          <button
            className=" hover:bg-light-purple hover:text-white transition-colors focus:bg-light-purple focus:text-white absolute top-2.5 right-2.5 inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:outline-none"
            aria-label="Close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M368 368L144 144M368 144L144 368"
              ></path>
            </svg>
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default Modal;
