import { Link } from "react-router-dom";
import LogoLight from "./icons/LogoLight";
// import { useForm } from "react-hook-form";
// import { object, string } from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useState } from "react";

const links = [
  {
    key: 0,
    link: "#!",
    label: "facebook",
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9374 7.75H16.3124C15.6451 7.75 15.1041 8.291 15.1041 8.95833V12.5833H19.9374C20.0748 12.5803 20.2052 12.6442 20.2869 12.7548C20.3686 12.8654 20.3915 13.0087 20.3483 13.1392L19.4541 15.7975C19.3717 16.0415 19.1437 16.2064 18.8862 16.2083H15.1041V25.2708C15.1041 25.6045 14.8335 25.875 14.4999 25.875H11.4791C11.1454 25.875 10.8749 25.6045 10.8749 25.2708V16.2083H9.06242C8.72875 16.2083 8.45825 15.9378 8.45825 15.6042V13.1875C8.45825 12.8539 8.72875 12.5833 9.06242 12.5833H10.8749V8.95833C10.8749 6.28896 13.0389 4.125 15.7083 4.125H19.9374C20.271 4.125 20.5416 4.3955 20.5416 4.72917V7.14583C20.5416 7.4795 20.271 7.75 19.9374 7.75Z"
          fill="#DBDBED"
        />
      </svg>
    ),
  },
  {
    key: 1,
    link: "#!",
    label: "instagram",
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8333 4.125H10.1667C6.82995 4.125 4.125 6.82995 4.125 10.1667V19.8333C4.125 23.17 6.82995 25.875 10.1667 25.875H19.8333C23.17 25.875 25.875 23.17 25.875 19.8333V10.1667C25.875 6.82995 23.17 4.125 19.8333 4.125ZM23.7604 19.8333C23.7538 21.9994 21.9994 23.7538 19.8333 23.7604H10.1667C8.00055 23.7538 6.24622 21.9994 6.23958 19.8333V10.1667C6.24622 8.00055 8.00055 6.24622 10.1667 6.23958H19.8333C21.9994 6.24622 23.7538 8.00055 23.7604 10.1667V19.8333ZM20.7396 10.4688C21.4069 10.4688 21.9479 9.92776 21.9479 9.26042C21.9479 8.59308 21.4069 8.05208 20.7396 8.05208C20.0722 8.05208 19.5313 8.59308 19.5313 9.26042C19.5313 9.92776 20.0722 10.4688 20.7396 10.4688ZM15 9.5625C11.997 9.5625 9.5625 11.997 9.5625 15C9.5625 18.0031 11.997 20.4375 15 20.4375C18.0031 20.4375 20.4375 18.0031 20.4375 15C20.4408 13.5569 19.8689 12.172 18.8484 11.1516C17.828 10.1311 16.4431 9.55929 15 9.5625ZM11.6771 15C11.6771 16.8352 13.1648 18.3229 15 18.3229C16.8352 18.3229 18.3229 16.8352 18.3229 15C18.3229 13.1648 16.8352 11.6771 15 11.6771C13.1648 11.6771 11.6771 13.1648 11.6771 15Z"
          fill="#DBDBED"
        />
      </svg>
    ),
  },
  {
    key: 2,
    link: "#!",
    label: "twitter",
    icon: (
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3422 8.61686C24.781 9.36545 24.1027 10.0184 23.3333 10.5506C23.3333 10.7462 23.3333 10.9417 23.3333 11.1481C23.3395 14.6992 21.921 18.1043 19.396 20.6C16.8708 23.0957 13.4503 24.4732 9.9012 24.4237C7.84938 24.4306 5.82383 23.9621 3.98323 23.0549C3.88398 23.0115 3.81997 22.9134 3.82035 22.805V22.6855C3.82035 22.5295 3.94675 22.4031 4.10268 22.4031C6.11957 22.3365 8.0645 21.6373 9.6623 20.4042C7.83674 20.3673 6.1942 19.2858 5.43828 17.6229C5.40011 17.5322 5.41199 17.4279 5.46966 17.348C5.52733 17.2682 5.62243 17.2242 5.72061 17.2319C6.27544 17.2876 6.83582 17.236 7.37113 17.0798C5.35586 16.6615 3.8416 14.9884 3.62489 12.9406C3.61719 12.8424 3.66121 12.7473 3.74106 12.6895C3.82089 12.6319 3.92502 12.6199 4.01581 12.6583C4.55661 12.8969 5.14042 13.0226 5.73147 13.0275C3.96561 11.8686 3.20288 9.66406 3.87464 7.66085C3.94399 7.4662 4.11058 7.3224 4.3132 7.2823C4.51582 7.2422 4.72459 7.31172 4.86278 7.46531C7.2457 10.0014 10.5199 11.5127 13.9949 11.6805C13.9059 11.3253 13.8622 10.9603 13.8646 10.5941C13.8971 8.67404 15.0851 6.96363 16.8725 6.2636C18.6597 5.56357 20.6926 6.01246 22.0195 7.40012C22.9238 7.22782 23.7982 6.92405 24.6147 6.49842C24.6745 6.46109 24.7503 6.46109 24.8102 6.49842C24.8475 6.55826 24.8475 6.63413 24.8102 6.69397C24.4147 7.59959 23.7466 8.35915 22.8991 8.86673C23.6412 8.78066 24.3703 8.60559 25.0708 8.34528C25.1298 8.30513 25.2072 8.30513 25.2662 8.34528C25.3156 8.36787 25.3526 8.41109 25.3672 8.46343C25.3818 8.51576 25.3726 8.57189 25.3422 8.61686Z"
          fill="#DBDBED"
        />
      </svg>
    ),
  },
  {
    key: 3,
    link: "https://www.linkedin.com/company/thriive-com/",
    label: "linkedin",
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.54167 4.125H23.4583C24.7931 4.125 25.875 5.20698 25.875 6.54167V23.4583C25.875 24.7931 24.7931 25.875 23.4583 25.875H6.54167C5.20698 25.875 4.125 24.7931 4.125 23.4583V6.54167C4.125 5.20698 5.20698 4.125 6.54167 4.125ZM10.1667 22.25C10.5003 22.25 10.7708 21.9795 10.7708 21.6458V13.1875C10.7708 12.8539 10.5003 12.5833 10.1667 12.5833H8.35417C8.0205 12.5833 7.75 12.8539 7.75 13.1875V21.6458C7.75 21.9795 8.0205 22.25 8.35417 22.25H10.1667ZM9.26042 11.375C8.2594 11.375 7.44792 10.5635 7.44792 9.5625C7.44792 8.56148 8.2594 7.75 9.26042 7.75C10.2614 7.75 11.0729 8.56148 11.0729 9.5625C11.0729 10.5635 10.2614 11.375 9.26042 11.375ZM21.6458 22.25C21.9795 22.25 22.25 21.9795 22.25 21.6458V16.0875C22.2893 14.1672 20.8696 12.5284 18.9633 12.2933C17.6305 12.1716 16.3392 12.7941 15.6042 13.9125V13.1875C15.6042 12.8539 15.3336 12.5833 15 12.5833H13.1875C12.8539 12.5833 12.5833 12.8539 12.5833 13.1875V21.6458C12.5833 21.9795 12.8539 22.25 13.1875 22.25H15C15.3336 22.25 15.6042 21.9795 15.6042 21.6458V17.1146C15.6042 16.1136 16.4157 15.3021 17.4167 15.3021C18.4177 15.3021 19.2292 16.1136 19.2292 17.1146V21.6458C19.2292 21.9795 19.4997 22.25 19.8333 22.25H21.6458Z"
          fill="#F2F2F2"
        />
      </svg>
    ),
  },
];

// Hubspot test
// const endpoint =
//   "https://api.hsforms.com/submissions/v3/integration/submit/39677440/b186105e-5558-4fd2-96ae-d37593c78115";

// Hubspot live
// const endpoint =
//   "https://api.hsforms.com/submissions/v3/integration/submit/39669329/0b4db05d-a27b-4642-9467-930297626e65";

const sitemapLinks = [
  { key: 0, title: "Meet the team", href: "/team" },
  { key: 1, title: "About us", href: "/about" },
  { key: 2, title: "Contact us", href: "/contact-us" },
  { key: 3, title: "FAQs", href: "/faq" },
];
// const schema = object({
//   email: string().email().required("Email is required"),
// }).required();
// const defaultRespones = {
//   status: null,
//   loading: false,
//   message: "",
// };
function Footer() {
  // const [response, setResponse] = useState(defaultRespones);
  // const {
  //   handleSubmit,
  //   register,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues: {
  //     email: "",
  //   },
  //   resolver: yupResolver(schema),
  // });

  // const onSubmit = (data) => {
  //   setResponse({
  //     status: null,
  //     loading: true,
  //     message: "",
  //   });
  //   fetch(endpoint, {
  //     body: JSON.stringify({
  //       fields: [{ name: "email", value: data.email }],
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     method: "post",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setResponse({
  //         status: "ok",
  //         loading: false,
  //         message: data?.inlineMessage ?? "Sent",
  //       });
  //       setTimeout(() => {
  //         setResponse(defaultRespones);
  //       }, 1400);
  //     })
  //     .catch((err) => {
  //       setResponse({
  //         status: "error",
  //         loading: false,
  //         message: "Failed to sent",
  //       });
  //     });
  // };
  return (
    <footer>
      <div className="bg-dark-matter text-aged-white bg-opacity-80 pt-8 pb-6 sm:pt-9 sm:pb-4">
        <div className="container">
          <div className="flex flex-wrap justify-between flex-col sm:flex-row">
            <div className="mb-[50px] sm:mb-0 max-w-max mx-auto sm:mx-0">
              <LogoLight />
              <div className="flex mt-6">
                {links.map((l) => (
                  <Link
                    aria-label={l.label}
                    to={l.link}
                    key={l.key}
                    className={`pr-3 mr-2 flex-shrink-0 ${
                      l.link === "#!" ? "pointer-events-none opacity-25" : ""
                    }`}
                  >
                    {l.icon}
                  </Link>
                ))}
              </div>
            </div>
            {/* <form
              onSubmit={handleSubmit(onSubmit)}
              className="grid gap-5 grid-cols-1"
            >
              <div className="max-w-[260px] w-full mx-auto mb-[50px] sm:mb-0 sm:w-[210px] md:w-[300px] sm:max-w-full sm:mx-0 flex-shrink-0">
                <div className="text-base pb-2.5 font-medium">
                  Stay in touch
                </div>
                <input
                  className="input w-full text-black"
                  name="email"
                  disabled={response.loading}
                  placeholder="Enter your email"
                  {...register("email")}
                />
                <button
                  className="button button-outline block my-2.5"
                  disabled={response.loading}
                  type="submit"
                >
                  Subscribe
                </button>
                {response.status && (
                  <div
                    className={`button ${
                      response.status === "ok" ? "success" : "error"
                    }`}
                  >
                    {response.message}
                  </div>
                )}
              </div>
            </form> */}
            <div className="w-[240px] mx-auto sm:w-auto sm:mx-0">
              <div className="text-base mb-4 sm:text-right font-bold">
                Site Map
              </div>
              <div className="flex flex-col sm:text-right">
                {sitemapLinks.map((l) => (
                  <Link
                    to={l.href}
                    key={l.key}
                    className="mb-2 text-14 leading-5.25"
                  >
                    {l.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="text-center text-12 text-lavendar-50 font-bold mt-9 lg:mt-10">
            ©{new Date().getFullYear()}, All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
