import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { easeInOutCubic } from "../easing";
import "./chatboxanimation.scss";

const ChatBoxAnimation = () => {
  const tl = useRef();

  useEffect(() => {
    gsap.defaults({ ease: easeInOutCubic, overwrite: false });
    gsap.set(".right-anim-area", { rotateY: 0, autoAlpha: 0 });
    gsap.set(".left-bar", { autoAlpha: 0 });
    gsap.set(".client-card-anim", { autoAlpha: 0 });
    gsap.set(".right-chat-main", { yPercent: 100, autoAlpha: 0 });
    gsap.set(".single-chat", { autoAlpha: 0 });
    gsap.set(".salary-guidance-box", { scale: 0.7, autoAlpha: 0 });
    gsap.set(".sg-body-content", { autoAlpha: 0 });
    gsap.set(".salary-range", { autoAlpha: 0 });
    gsap.set(".bottom-buttons", { autoAlpha: 0 });
    gsap.set(".pi-box", { scale: 0.7, autoAlpha: 0 });
    gsap.set(".single-pi-box", { autoAlpha: 0 });

    tl.current = gsap
      .timeline({
        repeat: -1,
        repeatDelay: 0.5,
        delay: 0,
      })
      .fromTo(
        ".right-anim-area",
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          duration: 0.4,
        }
      )
      .to(".right-anim-area", {
        rotateX: 0,
        rotateY: "2deg",
        duration: 2.9,
      })
      .to(
        ".left-bar",
        {
          autoAlpha: 1,
          duration: 0.45,
        },
        "<1"
      )
      .to(
        ".client-card-anim",
        {
          autoAlpha: 1,
          duration: 0.4,
          stagger: 0.15,
          delay: -0.5,
        },
        "<1"
      )
      .to(
        ".right-chat-main",
        {
          yPercent: 0,
          autoAlpha: 1,
          duration: 0.5,
          delay: -0.5,
        },
        "<1"
      )
      .to(
        ".single-chat",
        {
          autoAlpha: 1,
          duration: 0.8,
          stagger: 0.18,
        },
        "<1"
      )
      .to(
        ".right-anim-area",
        {
          rotateX: 0,
          rotateY: "-1deg",
          duration: 3.36,
        },
        "<1"
      )
      .to(
        ".salary-guidance-box",
        {
          autoAlpha: 1,
          scale: 1,
          duration: 0.4,
          ease: "back",
        },
        "<1"
      )
      .to(
        ".sg-body-content",
        {
          autoAlpha: 1,
          delay: -0.6,
          duration: 0.2,
        },
        "<1"
      )
      .to(
        ".salary-range",
        {
          autoAlpha: 1,
          duration: 0.4,
          delay: -0.4,
        },
        "<1"
      )
      .to(
        ".bottom-buttons",
        {
          autoAlpha: 1,
          duration: 0.4,
          delay: -0.4,
        },
        "<1"
      )
      .to(
        ".pi-box",
        {
          autoAlpha: 1,
          scale: 1,
          duration: 0.4,
          delay: -0.8,
          ease: "back",
        },
        "<1"
      )
      .to(
        ".single-pi-box",
        {
          autoAlpha: 1,
          duration: 0.6,
          delay: -0.2,
          stagger: 0.2,
        },
        "<1"
      )
      .to(".right-anim-area", { autoAlpha: 0, delay: 2, duration: 0.1 });

    return () => {
      tl.current?.kill();
    };
  }, []);

  return (
    <div className="fix-animation-z">
      <div className="right-anim-area">
        <div className="asset-1-screen relative">
          <img src="/img/bg-asset.svg" alt="" style={{ width: "100%" }} />
          <img className="topbar" src="/img/topbar.png" alt="" />

          <div className="left-bar absolute">
            <img src="/img/asset-part-2.png" alt="" />
          </div>

          <div className="client-space absolute">
            <span className="client-head client-card-anim">Your matches</span>

            <div className="client-cards">
              <div className="single-client-card client-card-anim">
                <img src="/img/client-1.png" alt="" />
              </div>
              <div className="single-client-card client-card-anim">
                <img src="/img/client-2.png" alt="" />
              </div>
              <div className="single-client-card client-card-anim">
                <img src="/img/client-3.png" alt="" />
              </div>
              <div className="single-client-card client-card-anim">
                <img src="/img/client-4.png" alt="" />
              </div>
              <div className="single-client-card client-card-anim">
                <img src="/img/client-5.png" alt="" />
              </div>
              <div className="single-client-card client-card-anim">
                <img src="/img/client-6.png" alt="" />
              </div>
            </div>
          </div>

          <div className="right-chat-main absolute">
            <img src="/img/asset-part-3.png" alt="" />

            <div className="chats absolute">
              <div className="single-chat" style={{ maxWidth: "80%" }}>
                <img src="/img/chat-1.png" alt="" />
              </div>
              <div
                className="single-chat chat-right"
                style={{ maxWidth: "60%" }}
              >
                <img src="/img/chat-2.png" alt="" />
              </div>
              <div
                className="single-chat chat-right"
                style={{ maxWidth: "60%" }}
              >
                <img src="/img/chat-3.png" alt="" />
              </div>
              <div
                className="single-chat chat-right"
                style={{ maxWidth: "60%" }}
              >
                <img src="/img/chat-4.png" alt="" />
              </div>
              <div className="single-chat" style={{ maxWidth: "80%" }}>
                <img src="/img/chat-5.png" alt="" />
              </div>
              <div className="single-chat message-box">
                <img src="/img/message-box.svg" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="asset-2 absolute">
          <div className="salary-guidance-box white-asset-box">
            <div className="sg-box-head">Salary Guidance</div>

            <div className="sg-box-body">
              <div className="sg-body-content">
                <img className="job-title" src="/img/job-title.png" alt="" />

                <img
                  className="salary-range"
                  src="/img/salary-range.png"
                  alt=""
                />

                <img
                  className="bottom-buttons"
                  src="/img/bottom-buttons.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="asset-3 absolute">
          <div className="pi-box white-asset-box">
            <div className="sg-box-head">Personalised Insights</div>
            <div className="sg-box-body">
              <div className="pi-content-boxes">
                <div className="single-pi-box">
                  <img src="/img/strengths.svg" alt="" />
                </div>
                <div className="single-pi-box">
                  <img src="/img/differentiators.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxAnimation;
