import { useController, useFormContext } from "react-hook-form";
import ReactSelect from "../Select";
import Label from "./Label";
import ErrorLabel from "./ErrorLabel";

function Select({
  label = "Label",
  name,
  labelClassName = "last:mb-0",
  options,
  ...props
}) {
  const { control } = useFormContext();
  const { field, fieldState } = useController({ control, name });
  return (
    <label htmlFor={field.name} className={`block w-full ${labelClassName}`}>
      <Label>{label}</Label>
      <ReactSelect
        id={field.name}
        options={options ?? []}
        {...props}
        {...field}
        styles={{
          control: {
            borderColor: props?.disabled
              ? "rgba(53, 64, 86, 0.08)"
              : fieldState?.error
              ? "rgb(239 68 68)"
              : "rgba(53, 64, 86, 0.16)",
            backgroundColor: props?.disabled
              ? "rgba(53, 64, 86, 0.08)"
              : fieldState?.error
              ? "rgb(216 78 78 / 0.1)"
              : "white",
          },
        }}
      />
      {fieldState?.error && (
        <ErrorLabel>
          {fieldState?.error ? fieldState?.error?.message ?? "Error" : ""}
        </ErrorLabel>
      )}
    </label>
  );
}

export { Select };
