import "./App.css";
import "normalize.css";
import "./styles/global.scss";
import { Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, useLocation } from "react-router-dom";
import Candidate from "./pages/candidate";
import { useEffect } from "react";
import Layout from "./components/Layout";
const About = lazy(() => import("./pages/about"));
const Client = lazy(() => import("./pages/client"));
const Team = lazy(() => import("./pages/team"));
const ContactUs = lazy(() => import("./pages/contact-us"));
const FaqPage = lazy(() => import("./pages/faq"));
const InvestorsPage = lazy(() => import("./pages/investors"));

const fallBack = <div style={{ minHeight: "100vh" }}></div>;

function App() {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Layout>
        <div className="App">
          <Routes>
            <Route path="/" element={<Candidate />} />
            <Route path="/candidate" element={<Candidate />} />
            <Route
              path="/client"
              element={
                <Suspense fallback={fallBack}>
                  <Client />
                </Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <Suspense fallback={fallBack}>
                  <About />
                </Suspense>
              }
            />
            <Route
              path="/faq"
              element={
                <Suspense fallback={fallBack}>
                  <FaqPage />
                </Suspense>
              }
            />
            <Route
              path="/team"
              element={
                <Suspense fallback={fallBack}>
                  <Team />
                </Suspense>
              }
            />
            <Route
              path="/contact-us"
              element={
                <Suspense fallback={fallBack}>
                  <ContactUs />
                </Suspense>
              }
            />
            <Route
              path="/investors"
              element={
                <Suspense fallback={fallBack}>
                  <InvestorsPage />
                </Suspense>
              }
            />
          </Routes>
        </div>
      </Layout>
    </>
  );
}

export default App;
