function BulletPoints({ data = [] }) {
  return (
    <ul className="flex flex-col max-w-max mx-auto lg:mx-0 lg:max-w-full mt-9 md:mt-0">
      {data.map(({ key, title, icon }) => (
        <li key={key} className="mb-3 last:mb-0 flex items-center">
          <div className="w-9 flex-shrink-0 h-9 mr-3">{icon}</div>
          <p className="text-space-blue font-bold text-base">{title}</p>
        </li>
      ))}
    </ul>
  );
}

export default BulletPoints;
