import { useController, useFormContext } from "react-hook-form";
import Label from "./Label";
import ErrorLabel from "./ErrorLabel";

function Input({
  label = null,
  name,
  labelClassName = "last:mb-0",
  inputType = "text",
  ...props
}) {
  const { control } = useFormContext();
  const { fieldState, field } = useController({ name, control });
  return (
    <label htmlFor={name} className={`block w-full ${labelClassName}`}>
      <Label>{label ?? name}</Label>
      <input
        className="input input__medium input__white-smoke w-full"
        {...props}
        {...field}
      />
      {fieldState?.error && (
        <ErrorLabel>
          {fieldState?.error ? fieldState?.error?.message ?? "Error" : ""}
        </ErrorLabel>
      )}
    </label>
  );
}

export { Input };
