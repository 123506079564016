import { forwardRef } from "react";
import ReactSelect from "react-select";

const Select = forwardRef(
  ({ id, styles = {}, className, disabled, ...props }, ref) => {
    return (
      <ReactSelect
        instanceId={id}
        className={className}
        isDisabled={disabled}
        isSearchable={false}
        placeholder="Select option"
        styles={{
          valueContainer: (base) => ({
            ...base,
            height: "40px",
          }),
          placeholder: (base) => ({
            ...base,
            color: "#3D3E72",
            fontWeight: 500,
            fontSsize: "16px",
            lineHeight: "20px",
          }),
          menuList: (base) => ({
            ...base,
            padding: "0px 0px",
          }),
          menu: (base) => ({
            ...base,
            marginTop: 0,
            marginLeft: "9px",
            width: "calc(100% - 18px)",
          }),
          control: (base, state) => ({
            ...base,
            height: 40,
            borderRadius: "4px",
            borderWidth: "1px",
            ...styles?.control,
            borderColor:
              state.isFocused || state.hasValue ? "#7073C2" : "#999999",
            background:
              state.isFocused || state.hasValue ? "#7073C2" : "#f7f7f7",

            boxShadow: 0,

            "&:hover": {
              borderColor: !state.isFocused ? "#432AE0" : "",
            },
          }),
          indicatorSeparator: (base) => ({
            ...base,
            visibility: !props?.isClearable ? "hidden" : "visible",
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            color: "rgba(61, 62, 114, 1)",
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
          }),
          input: (base, state) => ({
            ...base,
            color: state.isFocused ? "#3D3E72" : "#F2F2FF",
            fontWeight: 500,
          }),
          singleValue: (base, ...props) => {
            return {
              ...base,
              fontWeight: 500,
              color: "white",
            };
          },
          option: (base, state) => ({
            ...base,
            background:
              state.isSelected || state.isFocused ? "#F2F2FF" : "#F2F2FF",
            color: "#7073C2",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "25px",
            padding: "12px 17px 8px",
          }),
        }}
        {...props}
      />
    );
  }
);
Select.displayName = "Select";
export default Select;
