function Label({ children, className = "" }) {
  return (
    <span
      className={`"text-dark-purple text-base leading-5 mb-2 font-medium block ${className}`}
    >
      {children}
    </span>
  );
}

export default Label;
