function Testimonials({ title = "Testimonials", testimonials = [] }) {
  return (
    <div className="container">
      <h3 className="heading-3 text-center mt-2 mb-9.5 lg:mb-16">{title}</h3>
      <div className="row lg:px-[74px]">
        {testimonials.map((t) => (
          <div className="col w-full md:w-1/2 mb-6 lg:mb-10" key={t?.key}>
            <div className="drop-shadow-card p-6 lg:p-9 bg-white-smoke rounded-xl h-full">
              <div className="flex text-neutral-800 mb-6 lg:mb-8 items-center">
                <img
                  src={t?.image}
                  width="80"
                  height="80"
                  className="w-7 h-7 md:w-10 md:h-10"
                  alt={t?.name}
                />
                <div className="text-14 lg:text-22 pl-4 font-bold">
                  {t?.name}
                </div>
              </div>
              <div className="text-18 leading-5.5 lg:text-28 italic lg:leading-8 tracking-wide font-arial">
                “{t?.text}”
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
