function FeatureSection({
  features = [],
  title = "Features and benefits",
  subTitle = "Our world leading insights & tech will match your experience, strengths & motivations with the right business & environment to supercharge your career.",
}) {
  return (
    <section className="container">
      <div className="row justify-center text-center">
        <div className="col w-full md:w-6/8">
          <h3 className="heading-3 text-center mt-21.5 lg:mt-25 mb-4">
            {title}
          </h3>
          <p className="mb-18.5 lg:mb-28 text-left">{subTitle}</p>
        </div>
      </div>
      <div className="flex flex-col justify-center md:w-6/8 lg:w-full md:mx-auto">
        <div className="group">
          {features.map((f) => (
            <div
              className="row odd:flex-row even:flex-row-reverse mb-14 justify-between lg:justify-center"
              key={f.key}
            >
              <div className="col w-1/2 lg:w-3/8 self-center">
                <h4 className="text-18 lg:px-5 md:text-20 lg:text-40 font-bold leading-5.5 lg:leading-10 mb-3 pb-1">
                  {f.title}
                </h4>
                <p className="text-base lg:px-5 lg:text-20 lg:leading-5.5 text-washed-black font-medium">
                  {f.description}
                </p>
              </div>
              {!f?.image && (
                <div className="col hidden lg:block lg:w-1/8"></div>
              )}
              <div className="col w-1/2 lg:w-3/8">
                <div
                  className={`w-full ${
                    f?.image ? "" : "bg-medium-grey aspect-square w-full"
                  }`}
                >
                  {f?.image && (
                    <img
                      alt=""
                      className="w-full"
                      loading="lazy"
                      {...f.image}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
