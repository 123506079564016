import { useController, useFormContext } from "react-hook-form";
import ErrorLabel from "./ErrorLabel";
import Label from "./Label";

const LinkedinField = ({ name, ...rest }) => {
  const { control } = useFormContext();
  const { fieldState, field } = useController({ name, control });
  return (
    <label htmlFor={name} className="block w-full">
      <Label>LinkedIn profile username</Label>
      <div className="flex flex-col">
        <div className="mb-1.5 text-jumper-grey text-base leading-5 font-bold">
          https://www.linkedin.com/
        </div>
        <input
          type="text"
          className="input input__medium input__white-smoke w-full"
          name={name}
          {...rest}
          {...field}
        />
      </div>
      {fieldState?.error && (
        <ErrorLabel>
          {fieldState?.error ? fieldState?.error?.message ?? "Error" : ""}
        </ErrorLabel>
      )}
    </label>
  );
};

export default LinkedinField;
