export const bulletPoints = [
  {
    key: 0,
    icon: (
      <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#121639" />
        <path
          d="M14.3888 9.03617C14.2648 8.57369 13.7893 8.29932 13.3268 8.42337C12.8644 8.54741 12.59 9.02288 12.714 9.48537L13.3877 11.9971C13.5117 12.4596 13.9872 12.7339 14.4497 12.6099C14.9122 12.4858 15.1865 12.0104 15.0625 11.5479L14.3888 9.03617Z"
          fill="white"
        />
        <path
          d="M20.0272 11.444C20.3657 11.1054 20.3657 10.5564 20.0272 10.2178C19.6886 9.87926 19.1396 9.87926 18.801 10.2178L16.9613 12.0576C16.6227 12.3962 16.6227 12.9452 16.9613 13.2837C17.2998 13.6223 17.8488 13.6223 18.1874 13.2837L20.0272 11.444Z"
          fill="white"
        />
        <path
          d="M9.48432 12.7141C9.02182 12.5901 8.54637 12.8645 8.42237 13.327C8.29837 13.7895 8.57277 14.265 9.03527 14.389L11.5478 15.0626C12.0103 15.1866 12.4858 14.9122 12.6098 14.4497C12.7338 13.9872 12.4594 13.5118 11.9969 13.3878L9.48432 12.7141Z"
          fill="white"
        />
        <path
          d="M15.4559 14.3222C15.1316 14.1871 14.7578 14.2611 14.5094 14.5095C14.261 14.7579 14.187 15.1317 14.3221 15.456L18.6571 25.86C18.7959 26.193 19.1262 26.4052 19.4867 26.3931C19.8472 26.3809 20.1625 26.1468 20.2784 25.8052L21.3683 22.5946L25.1668 26.3931C25.5054 26.7316 26.0544 26.7316 26.393 26.3931C26.7315 26.0545 26.7315 25.5055 26.393 25.1669L22.5944 21.3684L25.8051 20.2785C26.1467 20.1626 26.3808 19.8473 26.393 19.4868C26.4051 19.1263 26.1929 18.796 25.8599 18.6572L15.4559 14.3222Z"
          fill="white"
        />
        <path
          d="M13.2831 18.1872C13.6215 17.8485 13.6212 17.2995 13.2825 16.9611C12.9437 16.6227 12.3948 16.6229 12.0564 16.9617L10.2183 18.8014C9.87989 19.1402 9.88015 19.6891 10.2189 20.0276C10.5576 20.366 11.1066 20.3657 11.445 20.027L13.2831 18.1872Z"
          fill="white"
        />
      </svg>
    ),
    title: "Apply once. No multiple job applications",
  },
  {
    key: 1,
    icon: (
      <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#121639" />
        <g clipPath="url(#clip0_64_9195)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.1157 27.8438C26.2903 27.8438 28.0532 26.0809 28.0532 23.9063C28.0532 21.7316 26.2903 19.9688 24.1157 19.9688C21.9411 19.9688 20.1782 21.7316 20.1782 23.9063C20.1782 26.0809 21.9411 27.8438 24.1157 27.8438ZM26.1823 23.2954C26.3669 23.1108 26.3669 22.8117 26.1823 22.6271C25.9978 22.4426 25.6986 22.4426 25.5141 22.6271L23.9582 24.183L23.0323 23.2571C22.8478 23.0726 22.5486 23.0726 22.3641 23.2571C22.1796 23.4417 22.1796 23.7408 22.3641 23.9254L23.9582 25.5195L26.1823 23.2954Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.916 8.80174C11.916 8.44525 12.205 8.15625 12.5615 8.15625H23.5348C23.8913 8.15625 24.1803 8.44525 24.1803 8.80174V9.12449C24.8933 9.12449 25.4713 9.70248 25.4713 10.4155V18.9449C24.9321 18.7579 24.3529 18.6562 23.75 18.6562C23.3179 18.6562 22.8981 18.7084 22.4963 18.8069H19.3391C19.1609 18.8069 19.0164 18.9514 19.0164 19.1296V21.0661C19.0164 21.1818 19.0773 21.2833 19.1687 21.3402C18.7429 22.099 18.5 22.9742 18.5 23.9062C18.5 25.1528 18.9344 26.2978 19.6601 27.1983H11.916C11.203 27.1983 10.625 26.6203 10.625 25.9073V10.4155C10.625 9.70248 11.203 9.12449 11.916 9.12449V8.80174ZM13.5297 11.061C13.3515 11.061 13.207 11.2055 13.207 11.3837V13.3202C13.207 13.4984 13.3515 13.6429 13.5297 13.6429H16.7572C16.9354 13.6429 17.0799 13.4984 17.0799 13.3202V11.3837C17.0799 11.2055 16.9354 11.061 16.7572 11.061H13.5297ZM13.5297 14.9339C13.3515 14.9339 13.207 15.0784 13.207 15.2567V17.1931C13.207 17.3714 13.3515 17.5159 13.5297 17.5159H16.7572C16.9354 17.5159 17.0799 17.3714 17.0799 17.1931V15.2567C17.0799 15.0784 16.9354 14.9339 16.7572 14.9339H13.5297ZM13.207 19.1296C13.207 18.9514 13.3515 18.8069 13.5297 18.8069H16.7572C16.9354 18.8069 17.0799 18.9514 17.0799 19.1296V21.0661C17.0799 21.2443 16.9354 21.3888 16.7572 21.3888H13.5297C13.3515 21.3888 13.207 21.2443 13.207 21.0661V19.1296ZM19.3391 11.061C19.1609 11.061 19.0164 11.2055 19.0164 11.3837V13.3202C19.0164 13.4984 19.1609 13.6429 19.3391 13.6429H22.5666C22.7449 13.6429 22.8893 13.4984 22.8893 13.3202V11.3837C22.8893 11.2055 22.7449 11.061 22.5666 11.061H19.3391ZM19.0164 15.2567C19.0164 15.0784 19.1609 14.9339 19.3391 14.9339H22.5666C22.7449 14.9339 22.8893 15.0784 22.8893 15.2567V17.1931C22.8893 17.3714 22.7449 17.5159 22.5666 17.5159H19.3391C19.1609 17.5159 19.0164 17.3714 19.0164 17.1931V15.2567Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_64_9195">
            <rect
              width="21"
              height="19.6875"
              fill="white"
              transform="translate(8 8.15625)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    title: "Companies will contact you",
  },
  {
    key: 2,
    icon: (
      <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#121639" />
        <path
          d="M9 9.40179C9 9.17989 9.18316 9 9.40909 9H17.5909C17.8168 9 18 9.17989 18 9.40179V10.2054C18 10.4273 17.8168 10.6071 17.5909 10.6071H9.40909C9.18316 10.6071 9 10.4273 9 10.2054V9.40179Z"
          fill="white"
        />
        <path
          d="M9 12.6161C9 12.3942 9.18316 12.2143 9.40909 12.2143H15.9545C16.1805 12.2143 16.3636 12.3942 16.3636 12.6161V13.4196C16.3636 13.6415 16.1805 13.8214 15.9545 13.8214H9.40909C9.18316 13.8214 9 13.6415 9 13.4196V12.6161Z"
          fill="white"
        />
        <path
          d="M9 15.8304C9 15.6085 9.18316 15.4286 9.40909 15.4286H14.3182C14.5441 15.4286 14.7273 15.6085 14.7273 15.8304V16.6339C14.7273 16.8558 14.5441 17.0357 14.3182 17.0357H9.40909C9.18316 17.0357 9 16.8558 9 16.6339V15.8304Z"
          fill="white"
        />
        <path
          d="M20.4546 13.0178C21.3226 13.0178 22.155 13.3565 22.7688 13.9593C23.3826 14.5621 23.7274 15.3796 23.7274 16.2321C23.7274 17.0846 23.3826 17.9022 22.7688 18.505C22.155 19.1077 21.3226 19.4464 20.4546 19.4464C19.5867 19.4464 18.7542 19.1077 18.1405 18.505C17.5267 17.9022 17.1819 17.0846 17.1819 16.2321C17.1819 15.3796 17.5267 14.5621 18.1405 13.9593C18.7542 13.3565 19.5867 13.0178 20.4546 13.0178ZM20.4546 21.0535C24.071 21.0535 27.0001 22.4919 27.0001 24.2678V25.875H13.9092V24.2678C13.9092 22.4919 16.8383 21.0535 20.4546 21.0535Z"
          fill="white"
        />
      </svg>
    ),
    title: "Receive personalised insights.",
  },
];

export const features = [
  {
    key: 0,
    title: "Increase your salary",
    description: "Understand your true market value",
    image: {
      src: "/img/candidate-feature-1.png",
      srcSet:
        "/img/candidate-feature-1-mb.png 768w, /img/candidate-feature-1.png 878w",
      width: "870",
      height: "516",
    },
  },
  {
    key: 1,
    title: "The right companies will call you",
    description: "We've reversed the recruitment process",
    image: {
      src: "/img/candidate-feature-2.png",
      srcSet:
        "/img/candidate-feature-2-mb.png 768w, /img/candidate-feature-2.png 878w",
      width: "878",
      height: "506",
    },
  },
  {
    key: 2,
    title: "Personalised insights",
    description: "Gain insights on your key differentiators & strengths",
    image: {
      src: "/img/candidate-feature-3.png",
      srcSet:
        "/img/candidate-feature-3-mb.png 768w, /img/candidate-feature-3.png 878w",
      width: "878",
      height: "520",
    },
  },
];

export const testimonials = [
  {
    key: 0,
    name: "Alex",
    image: "/img/home/alex.png",
    text: "It has taken the stress & frustration out of my job search.",
  },
  {
    key: 1,
    name: "Scott",
    image: "/img/home/scott.png",
    text: "I realised I was underpaid in the market and could earn more.",
  },
  {
    key: 2,
    name: "Natasha",
    image: "/img/home/natasha.png",
    text: "The feedback I received was invaluable! I got to understand my strengths, plus it highlighted areas I need to work on",
  },
  {
    key: 3,
    name: "Sarah",
    image: "/img/home/sarah.png",
    text: "During the trial, I was matched with 3 companies",
  },
];
