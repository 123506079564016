function ErrorLabel({ children, className = "" }) {
  return (
    <span
      className={`mt-2 flex min-h-[18px] items-center font-medium text-red-500 ${className}`}
    >
      {children}
    </span>
  );
}

export default ErrorLabel;
