import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ChatBoxAnimation from "../components/chatBoxAnimation/chatBoxAnimation";
import { bulletPoints, features, testimonials } from "../content/home";
import FeatureSection from "../components/shared/sections/FeatureSection";
import Testimonials from "../components/shared/sections/Testimonials";
import BulletPoints from "../components/shared/BulletPoints";
import "../styles/home.scss";
import Modal from "../components/Modal";
import LinkedinField from "../components/Form/LinkedinField";
import { Input } from "../components/Form";

// @TEST
// const endpoint = 'https://pegnuu40a5.execute-api.ap-southeast-2.amazonaws.com/dev/register';

// const endpoint = "/api/register";

// Hubspot live
// const endpoint =
//   "https://api.hsforms.com/submissions/v3/integration/submit/39669329/c0225214-255d-4b05-878d-fc01db2e68ed";

// Hubspot Test
const endpoint =
  "https://api.hsforms.com/submissions/v3/integration/submit/39669329/e7cb4ec2-4cac-4ca7-b4b5-129e7fc145f8";

const schema = object({
  email: string().email().required("Email is required"),
}).required();

const defaultRespones = {
  status: null,
  loading: false,
  message: "",
};
const defaultValues = { email: "", linkedin: "" };
const Candidate = () => {
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(defaultRespones);
  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = form;
  const onSubmit = async ({ email, linkedin }) => {
    setResponse({ status: null, loading: true, message: "" });
    try {
      const response = await fetch(endpoint, {
        method: "post",
        // mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fields: [
            { name: "email", value: email },
            {
              name: "linkedin_id",
              value: linkedin || "",
            },
          ],
          context: {
            pageUri: window?.location?.href,
            pageName: "Candidate",
          },
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to register interest");
      }
      reset(defaultValues);
      setResponse({
        status: "ok",
        loading: false,
        message: "",
      });
      setTimeout(() => {
        setResponse(defaultRespones);
        setOpen(false);
      }, 1200);
    } catch (error) {
      setResponse({
        status: "error",
        loading: false,
        message: "Failed to register email",
      });
    }
  };
  useEffect(() => {
    if (!open) {
      reset(defaultValues);
      setResponse(defaultRespones);
    }
  }, [open, reset]);
  return (
    <>
      <main className="home">
        <section className="hero-area">
          <div className="container">
            <div className="row justify-center flex-col-reverse lg:flex-row lg:justify-start">
              <div className="col w-full md:w-4/6 lg:w-3/8 mx-auto lg:mx-0 pt-16 md:pt-[50px]">
                <div className="heading heading-1 text-center lg:text-left lg:!leading-[66px]">
                  <span className="relative underline-anim block lg:inline max-w-max mx-auto pt-4 md:pt-8">
                    Accelerate{" "}
                    <img
                      src="/img/accelerate-underline.svg"
                      className="candidate"
                      alt=""
                    />
                  </span>
                  your career.
                </div>
                <h2 className="text-graphite text-base leading-5.5 md:leading-6 font-medium mt-3 mb-7 max-w-[380px] text-center lg:text-left mx-auto lg:mx-0">
                  Recruitment reimagined. Let us be your secret weapon.
                </h2>
                <div className="flex flex-col-reverse md:flex-col mb-[114px] lg:mb-0">
                  <BulletPoints data={bulletPoints} />
                  <button
                    className="button button-large primary mt-5 max-w-max mx-auto lg:mx-0"
                    type="button"
                    onClick={() => {
                      if (!response.loading) reset(defaultValues);
                      setOpen(true);
                    }}
                  >
                    Register interest
                  </button>
                  <Modal
                    open={open}
                    title="Register for candidate"
                    onOpenChange={(v) => {
                      if (response.loading) return;
                      setOpen(v);
                    }}
                  >
                    <FormProvider {...form}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid gap-4 grid-cols-1 mx-auto lg:mx-0 mt-2">
                          <LinkedinField
                            name="linkedin"
                            disabled={response.loading}
                          />

                          <Input
                            label="Email"
                            name="email"
                            disabled={response.loading}
                          />
                          <button
                            className={`button button-large ${
                              response.loading ? "submitting" : ""
                            } ${
                              response.status === "ok"
                                ? "success pointer-events-none"
                                : response.status === "error" ||
                                  response.loading
                                ? "grey"
                                : "primary"
                            } max-w-max lg:mx-0`}
                            type="submit"
                          >
                            {response.status !== "ok"
                              ? "Register"
                              : "E-mail successfully registered."}
                          </button>
                        </div>
                        <span className="text-12 font-bold text-error">
                          {response.status === "error" && response.message}
                        </span>
                      </form>
                    </FormProvider>
                  </Modal>
                </div>
              </div>

              <div className="col w-full lg:w-5/8 lg:bg-home bg-no-repeat bg-contain pt-9 md:pt-12 lg:pt-[58px]">
                <ChatBoxAnimation />
              </div>
            </div>
          </div>
        </section>
        <FeatureSection
          subTitle="Our world leading insights & tech will match your experience, strengths & potential with the right roles & environment to supercharge your career."
          features={features}
        />
        <section className="bg-no-repeat bg-contain md:bg-cover relative ">
          <img src="/img/home/wave-bg.svg" alt="" />
          <div className="container absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <img
              src="/img/home/screen.png"
              srcSet="/img/home/screen-mb.png 540w, /img/home/screen.png 800w"
              loading="lazy"
              width="2273"
              height="1184"
              alt="laptop and mobile product overview screen"
            />
          </div>
        </section>
        <section className="pt-16">
          <Testimonials
            testimonials={testimonials}
            title="Beta Testing Feedback"
          />
        </section>
        <section className="pt-28 thrive-work overflow-hidden mb-[120px] md:mb-[180px] lg:mb-[160px]">
          <div className="container">
            <h3 className="heading-3 text-center mt-2 mb-16">
              How THRIIVE works
            </h3>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="/img/hw-candidate-lg.png"
                width="2417"
                height="966"
              />
              <source
                media="(min-width:540px)"
                srcSet="/img/hw-candidate-md.png"
                width="1508"
                height="602"
              />
              <img
                src="/img/hw-candidate.png"
                alt="how thriive works"
                className="w-full max-w-[300px] mx-auto md:max-w-full"
                width="611"
                height="2996"
              />
            </picture>
          </div>
        </section>
      </main>
    </>
  );
};

export default Candidate;
